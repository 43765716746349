$font-h1-desktop: px(96);
$font-h2-desktop: px(60);
$font-h3-desktop: px(40);
$laptop-multiplier: 0.8;

@mixin font-h1 {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.02em;
  font-size: px(42);
  text-wrap: balance;

  @include bp(tablet) {
    font-size: $font-h1-desktop * $laptop-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h1-desktop;
  }
}

@mixin font-h2-mobile {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  font-size: px(28);
  text-wrap: balance;
}

@mixin font-h2 {
  @include font-h2-mobile;

  @include bp(tablet) {
    font-size: $font-h2-desktop * $laptop-multiplier;
  }

  @include bp(laptop) {
    font-size: $font-h2-desktop;
  }
}

@mixin font-h3-mobile {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  font-size: px(24);
  text-wrap: balance;
}

@mixin font-h3-desktop {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-wrap: balance;
  font-size: $font-h3-desktop * $laptop-multiplier;

  @include bp(laptop) {
    font-size: $font-h3-desktop;
  }
}

@mixin font-h3 {
  @include font-h3-mobile;

  @include bp(tablet) {
    @include font-h3-desktop;
  }
}

@mixin font-h4 {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: px(24);
  text-wrap: balance;
}

@mixin font-h5 {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  font-size: px(24);
  text-wrap: balance;

  @include bp(tablet) {
    font-size: px(20);
  }
}

@mixin font-subheader-mobile {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  text-wrap: pretty;
  font-size: px(12);
  letter-spacing: 0;
}

@mixin font-subheader-desktop {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  text-wrap: pretty;
  font-size: px(18);
  letter-spacing: 0;
}

@mixin font-subheader {
  @include font-subheader-mobile;

  @include bp(tablet) {
    @include font-subheader-desktop;
  }
}

@mixin font-small-subheader {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(12);
  line-height: 100%;
  text-transform: uppercase;
  text-wrap: pretty;

  @include bp(tablet) {
    font-size: px(14);
  }
}

@mixin font-body-large {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(16);
  line-height: 130%;
  text-wrap: pretty;

  @include bp(tablet) {
    font-size: px(24);
  }
}

@mixin font-body {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(16);
  line-height: 130%;
  text-wrap: pretty;

  @include bp(tablet) {
    font-size: px(18);
  }
}

@mixin font-button {
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: px(16);
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin font-image-caption {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 300;
  font-size: px(12);
  line-height: 140%;
  text-transform: none;
}
