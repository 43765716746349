@import "@/styles/shared";
.bg {
  @include gpu;
  @include position-100(fixed);
  @include z-index(navigation-mobile-menu-bg);
  background-color: var(--dark-purple);
  opacity: 0;
  pointer-events: none;
}

.bgBlur {
  @include gpu;
  @include position-100(fixed);
  @include z-index(navigation-mobile-menu-blur);
  backdrop-filter: blur(10px);
  pointer-events: none;
  opacity: 0;
}

.NavigationMobileMenu {
  @include gpu;
  @include z-index(navigation-mobile-menu);
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: var(--dark-purple);
  color: var(--grey-light);
  width: 80%;
  overflow: auto;
  transform: translateX(100%);
}

.closeButton {
  @include reset-button;
  position: absolute;
  top: px(24);
  right: px(24);
  opacity: 0;
  z-index: 3;
}

.x {
  width: 100%;
  width: px(30);
  display: block;
}

.inner {
  padding: px(70) px(24);
  opacity: 0;
}

.list {
  display: flex;
  flex-direction: column;
  gap: px(34);
}

.level1Title {
  @include reset-button;
  @include flex-center;
  gap: px(5);
}

.level1Title__icon {
  width: px(10);
  transition: transform 0.3s;

  .isActive & {
    transform: rotate(-180deg);
  }

  svg {
    display: block;
  }
}

.level1Title__text,
.level1Link,
.dropdownContent__link {
  @include font-subheader-desktop;
}

.dropdownContent {
  height: 0;
}

.dropdownContent__list {
  display: flex;
  flex-direction: column;
  gap: px(34);
  padding-top: px(34);
  padding-left: px(34);
}

.dropdownContent__listItem {
  pointer-events: none;
  opacity: 0;
}

.activeLink {
  color: var(--purple);
}
