@use 'sass:list';
@import 'vars';

// ================================================
// GPU fix
// ================================================
@mixin gpu {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;

  .safari & {
    perspective: none; // otherwise text and icons become blurry
  }
}

// ================================================
// Same height/width box
// ================================================
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

// ================================================
// REM calc
// ================================================
@function px($px) {
  @return $px * 0.0625rem;
}

// ================================================
// Top left positioning
// ================================================
@mixin position-0 {
  top: 0;
  left: 0;
}

// ================================================
// Full width/height positioning
// ================================================
@mixin position-100($pos: relative) {
  position: $pos;
  @include position-0;
  @include box(100%);
}

// ================================================
// Center things horizontally in flexbox
// ================================================
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// ================================================
// Center things vertically in flexbox
// ================================================
@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

// ================================================
// Center something with abs/fixed positioning
// ================================================
@mixin transform-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// ================================================
// Z-Index for main components
// ================================================
@mixin z-index($id) {
  z-index: list.index($elements, $id) + 100;
}

// ================================================
// Media queries
// ================================================
@mixin bp($size, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: #{map-get($layout, $size)}px) {
      @content;
    }
  } @else {
    @media (min-width: #{map-get($layout, $size)}px) {
      @content;
    }
  }
}

@mixin bp-custom($query, $is-max-width: false) {
  @if $is-max-width {
    @media (max-width: #{$query}) {
      @content;
    }
  } @else {
    @media (min-width: #{$query}) {
      @content;
    }
  }
}

@mixin bp-height($px, $is-max-width: false) {
  @if $is-max-width {
    @media (max-height: #{$px}px) {
      @content;
    }
  } @else {
    @media (min-height: #{$px}px) {
      @content;
    }
  }
}

// ================================================
// Hover
// ================================================
@mixin hover {
  @media (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

// ================================================
// Reset ul
// ================================================
@mixin reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

// ================================================
// Reset button
// ================================================
@mixin reset-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  appearance: none;
  color: currentColor;
}

// ================================================
// Button
// ================================================

@mixin button-colors {
  background-color: var(--button-bg);
  backdrop-filter: blur(#{px(14)});
  border: px(1) solid transparent;
  color: var(--text-bold);
}

@mixin button-transition-values {
  transition: border-color $transition-short, background-color $transition-short, padding-right $transition-short;
}

// ================================================
// Hide scrollbar
// ================================================

@mixin hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

// ================================================
// Reduced motion
// ================================================

@mixin reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    @content;
  }
}

// ================================================
// Theming
// ================================================

@mixin dark-theme {
  // Text
  --text-color: var(--grey-light);
  --text-color-subtle: var(--grey-light);

  // Background
  --bg-color: var(--dark-purple);

  /* ================
  INVERT
  ================ */
  --text-color-invert: var(--dark-purple);
  --text-color-subtle-invert: var(--dark-purple-light);

  // Background-invert
  --bg-color-invert: var(--grey-light);
}

@mixin light-theme {
  // Text
  --text-color: var(--dark-purple);
  --text-color-subtle: var(--dark-purple-light);

  // Background
  --bg-color: var(--grey-light);

  // Button
  --button-border: var(--dark-purple-light-2);
  --button-color: var(--dark-purple);
  --button-color-hover: var(--grey-light);
  --button-bg: var(--dark-purple);

  // Line
  --line-color: var(--dark-purple-light);

  // Button Secondary
  --button-color-hover-secondary: var(--purple);

  // Input
  --input-border-color: var(--dark-purple-light-2);

  /* ================
  INVERT
  ================ */
  --text-color-invert: var(--grey-light);
  --text-color-subtle-invert: var(--grey-light);

  // Background-invert
  --bg-color-invert: var(--dark-purple);
}

// ================================================
// Grid
// ================================================

@mixin grid {
  display: grid;
  column-gap: var(--gutter);
  grid-template-columns: repeat(4, 1fr);

  @include bp(mobile) {
    grid-template-columns: repeat(12, 1fr);
  }
}
