@import "@/styles/shared";
.Navigation {
  @include z-index(navigation);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--grey-light);
}

.inner {
  width: 100%;
  padding: 0 var(--page-gutter);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  position: relative;
  border-bottom: 1px solid var(--grey);
}

.logoLink {
  display: block;
}

.logoContainer {
  display: block;
  width: px(63);
}

.rightContent {
  @include flex-center;
}

.rightContent__list {
  @include flex-center;
  gap: px(40);

  @include bp(mobile, true) {
    display: none;
  }
}

.level1Title,
.level1Link,
.loginLink,
.dropdownContent__link {
  @include reset-button;
  @include font-small-subheader;
  transition: color 0.3s;

  @include hover {
    color: var(--purple);
  }
}

.level1Link {
  position: relative;

  &.activeLink {
    font-weight: 700;
  }
}

.activeBall {
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--text-color);
  top: calc(100% + 3px);
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}

.level1Title {
  @include flex-center;
  gap: px(7);

  &.isActive {
    color: var(--purple);
  }

  &.linkIsActive {
    font-weight: 700;
  }
}

.level1Title__text {
  position: relative;
}

.level1Title__icon {
  @include flex-center;
  transition: transform 0.3s;

  svg {
    width: px(9);
  }

  .isActive & {
    transform: rotate(-180deg);
  }
}

.dropdownContentBg {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--grey-light);
  z-index: 1;
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  border-bottom: 1px solid var(--grey);
}

.dropdownContent {
  position: absolute;
  top: 100%;
  padding: 0 0 px(32) 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}

.dropdownContent__list {
  display: flex;
  flex-direction: column;
  gap: px(16);
}

.dropdownContent__link {
  white-space: nowrap;

  &.activeLink {
    font-weight: 700;
  }
}

.loginLink,
.loginLine {
  @include bp(mobile, true) {
    display: none;
  }
}

.loginLine {
  width: 1px;
  height: px(16);
  background-color: var(--dark-purple);
  opacity: 0.3;
  margin: 0 px(40);
}

.mobileButton {
  @include z-index(navigation-mobile-toggle);
  @include reset-button;
  height: var(--header-height);
  width: px(35);
  @include flex-center;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: var(--page-gutter);

  &:focus {
    outline: 1px solid var(--purple);
  }

  @include bp(mobile) {
    display: none;
  }
}

.mobileButton__line {
  width: px(22);
  height: 1px;
  background-color: var(--text-color);

  &.line1 {
    margin-bottom: px(7);
  }
}
