@use 'sass:map';

// ================================================
// Breakpoints
// ================================================
$layout: (
  mobile: 768,
  tablet: 967,
  laptop: 1200,
  desktop: 1512,
  xl: 1800,
);
$layout-mobile: map.get($layout, mobile) + px;
$layout-tablet: map.get($layout, tablet) + px;
$layout-laptop: map.get($layout, laptop) + px;
$layout-desktop: map.get($layout, desktop) + px;
$layout-xl: map.get($layout, xl) + px;

// ================================================
// Colors
// ================================================

// General
$white: #fff;
$white-rgb: 255, 255, 255;
$black: #000;
$black-rgb: 0, 0, 0;

// Purple
$dark-purple: #302c32;
$dark-purple-light: #67636a;
$dark-purple-light-2: #c3c1c4;
$purple: #734be2;
$purple-light: #8461e6;

// Grey
$grey: #e6e6e6;
$grey-light: #f2f2f2;

// Yellow
$yellow: #f9e741;
$yellow-light: #fbf18d;

// Red
$red: #ff070b;

// Background colors
$background-colours: light, dark;

// ================================================
// Grid
// ================================================
$gutter: px(16);
$page-gutter: px(70);
$page-gutter-mobile: px(24);

// ================================================
// General
// ================================================
$transition-short: 0.25s;
$content-top-spacing-desktop: #{px(175)};
$content-top-spacing-mobile: #{px(105)};
$content-footer-spacing-desktop: #{px(120)};
$content-footer-spacing-mobile: #{px(80)};
$section-spacing-desktop: #{px(64)};
$section-spacing-mobile: #{px(40)};
$section-max-width: px(1440);
$section-max-width-mobile: px(800);
$header-height: px(80);
$header-height-mobile: px(64);

// ================================================
// Components
// ================================================
$button-height: px(52);
$button-padding: px(24);
$circle-button-dimensions: px(56);

// ================================================
// Fonts
// ================================================
$primary-font-name: 'Tid';
$secondary-font-name: 'Lab Grotesque Mono';
$primary-fonts: $primary-font-name, serif;
$secondary-fonts: $secondary-font-name, sans-serif;

// ================================================
// z-index data (see z-index mixin)
// ================================================
$elements: main, navigation, navigation-mobile-toggle, navigation-mobile-menu-bg, navigation-mobile-menu-blur,
  navigation-mobile-menu, wipe, mobile-in-progress, preview-button;
