@import "@/styles/shared";
// Global styling, normalize overwritten
@import 'fonts';
@import 'shared';

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* stylelint-disable-next-line */
:root {
  // General
  --white: #{$white};
  --white-rgb: #{$white-rgb};
  --black: #{$black};
  --black-rgb: #{$black-rgb};
  --dark-purple: #{$dark-purple};
  --dark-purple-light: #{$dark-purple-light};
  --dark-purple-light-2: #{$dark-purple-light-2};
  --purple: #{$purple};
  --purple-light: #{$purple-light};
  --grey: #{$grey};
  --grey-light: #{$grey-light};
  --yellow: #{$yellow};
  --yellow-light: #{$yellow-light};
  --red: #{$red};

  // Grid
  --gutter: #{$gutter};
  --page-gutter: #{$page-gutter-mobile};

  @include bp(tablet) {
    --page-gutter: #{$page-gutter};
  }

  // General
  --content-top-spacing: #{$content-top-spacing-mobile};
  --content-footer-spacing: #{$content-footer-spacing-mobile};
  --section-spacing: #{$section-spacing-mobile};
  --header-height: #{$header-height-mobile};
  --section-max-width: #{$section-max-width-mobile};

  @include bp(tablet) {
    --content-footer-spacing: #{$content-footer-spacing-desktop};
    --content-top-spacing: #{$content-top-spacing-desktop};
    --header-height: #{$header-height};
    --section-max-width: #{$section-max-width};
  }

  @include bp(tablet) {
    --section-spacing: #{$section-spacing-desktop};
  }

  // Components
  --button-height: #{$button-height};
  --button-padding: #{$button-padding};
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: initial;
  min-height: 100%;
  height: auto;
}

body {
  @include light-theme;
  font-family: $primary-fonts;
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-size: px(16);
  height: auto;
  background-color: var(--bg-color);
  color: var(--text-color);

  // Disabling interaction
  &[data-enable-interaction='false'] {
    pointer-events: none !important;

    * {
      pointer-events: none !important;
    }
  }
}

main {
  @include z-index(main);
  min-height: 100svh;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

// ====================================
// Lenis
// ====================================
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  display: none !important;
}
